import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });

import modules from '../state/modules'

const store = createStore({
  modules,
  plugins: [
    createPersistedState({
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      }
    })
  ],
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== 'production',
  state: {
    token: null,
    refreshToken: null,
    rememberMe: false,
    user: null,
    isResponseWaitingFromServer: false,
    language: "en"
  },
  getters: {
    token(state) {
      return state.token;
    },
    refreshToken(state) {
      return state.refreshToken;
    },
    existToken(state){
      return state.token !== null;
    },
    existRefreshToken(state){
      return state.refreshToken !== null;
    },
    existTokenAndRefreshToken(state) {
      return state.existToken
          && state.existRefreshToken;
    },
    rememberMe(state){
      return state.rememberMe;
    },
    activeUser(state){
      return state.user
    },
    isLoggedIn(state, getters){
      return getters.existToken;
    },
    isResponseWaitingFromServer(state){
      return state.isResponseWaitingFromServer;
    },
    isAdminUser(state){
      return state.user.roles
          && state.user.roles
              .map(role => role.name.toLowerCase())
              .includes("admin");
    },
    activeUserAnyStore(state){
      return state.user.storeId !== undefined
          && state.user.storeId !== null;
    },
    language(state){
      return state.language;
    }
  },
  mutations: {
    setToken(state, { token }) {
      state.token = token;
    },
    setRefreshToken(state, { refreshToken }){
      state.refreshToken = refreshToken;
    },
    setRememberMe(state, { rememberMe }){
      state.rememberMe = rememberMe;
    },
    clearToken(state) {
      state.token = null;
    },
    setUser(state, { user }){
      state.user = user;
    },
    setIsResponseWaitingFromServer(state, { isWaiting }) {
      state.isResponseWaitingFromServer = isWaiting;
    },
    setStoreId(state, { storeId }){
      state.user.storeId = storeId;
    },
    setLanguage(state, { language }){
      state.language = language;
    },
  },
  actions: {
    setToken({ commit }, { token }) {
      commit('setToken', { token });
    },
    setRefreshToken({ commit }, { refreshToken }) {
      commit('setRefreshToken', { refreshToken });
    },
    setRememberMe({ commit }, { rememberMe }) {
      commit('setRememberMe', { rememberMe });
    },
    logout({ commit }) {
      commit('clearToken');
    },
    setUser({ commit }, { user }){
      commit('setUser', { user });
    },
    setIsResponseWaitingFromServer({ commit }, { isWaiting }) {
      commit('setIsResponseWaitingFromServer', { isWaiting });
    },
    setStoreId({ commit }, { storeId }) {
      commit('setStoreId', { storeId });
    },
    setLanguage({ commit }, { language }) {
      commit('setLanguage', { language });
    },
  },
})

export default store

