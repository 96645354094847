import axios from 'axios';
import store from '@/stores/store';
import router from "@/router";

const api = axios.create({
    baseURL: 'https://api.tradecrafter.site/',

    // Add any other global configuration here
});

// Request interceptor to add the JWT token to the headers
api.interceptors.request.use((config) => {

    store.dispatch('setIsResponseWaitingFromServer', { isWaiting: true });

    const token = store.getters.token;

    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
        config.headers['StoreId'] = store.getters.activeUser.storeId;
    }

    return config;
});

// Response interceptor to handle token refresh and expiration
api.interceptors.response.use(
    (response) => {
        // Handle successful responses here
        store.dispatch('setIsResponseWaitingFromServer', {isWaiting: false});

        return response;
    },
    function (error)  {
        if (error.response) {
            if (error.response.status === 401) {
                if (store.getters.rememberMe && store.getters.existRefreshToken){
                    const data ={
                        token: store.getters.token,
                        refreshToken: store.getters.refreshToken
                    }

                    api.post('users/refresh-token', data)
                        .then(async (response) => {
                            const token = response.data.data.token;
                            const refreshToken = response.data.data.refreshToken;

                            if (token && refreshToken){
                                await store.dispatch('setToken', {token});
                                await store.dispatch('setRefreshToken', {refreshToken});
                            }

                            router.go();
                        })
                        .catch((error) => {
                            console.log(error)
                            store.dispatch('logout');
                            router.push('/login');
                        });
                } else {
                    store.dispatch('logout');
                    router.push('/login');
                }
            }
            if (error.response.status === 500 && error.response.data.reason === "Invalid refresh token!") {
                store.dispatch('logout');
                router.push('/login');
            }
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
        }

        store.dispatch('setIsResponseWaitingFromServer', { isWaiting: false });

        return Promise.reject(error);
    }
);

export default api;