import { authHeader } from './auth-header';
import api from '../api';
import store from '../../stores/store';

export const userService = {
    login,
    loginUser,
    logout,
    register,
    getAll,
    getActiveUser,
    isLoggedIn
};

function loginUser(username, password, rememberMe) {
    return new Promise((resolve, reject) => {

        const data = { username, password };

        api.post('users/login', data)
            .then(async (response) => {
                const token = response.data.data.accessTokenModel.token;
                const refreshToken = response.data.data.accessTokenModel.refreshToken;
                const user = response.data.data.user;

                await store.dispatch('setRememberMe', {rememberMe});

                if (rememberMe && refreshToken){
                    await store.dispatch('setRefreshToken', {refreshToken});
                }

                if (token){
                    await store.dispatch('setToken', {token});
                    await store.dispatch('setUser', {user})
                    resolve(response);
                }
            })
            .catch((error) => {
                reject(error);
            });
    });
}

function login(email, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password })
    };

    return fetch(`/users/authenticate`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // login successful if there's a jwt token in the response
            if (user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
            }
            return user;
        });
}

function logout() {
    // remove user from local storage to log user out
    //localStorage.removeItem('user');
    store.dispatch('logout');
}

function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };
    return fetch(`/users/register`, requestOptions).then(handleResponse);
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`/users`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

async function getActiveUser() {
    return await store.getters.activeUser;
}

async function isLoggedIn(){
    return await store.getters.existToken;
}